@import "~@/assets/scss/global.scss";


















































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-aside {
    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      ::v-deep {
        .el-range-input {
          width: 70px;
        }
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    .el-form-item__label {
      padding-right: 0;
    }
  }

  .el-main {
    .el-header {
      .span-spec-name {
        font-weight: bold;
        font-size: 20px;
      }

      .span-spec-count {
        margin-left: 20px;
        font-size: 16px;
      }

      .btn-set-num {
        float: right;
        margin-right: 225px;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-copy {
        color: green;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-delete:hover {
        text-decoration: underline;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }
    }

    .el-footer {
      vertical-align: middle;

      .el-button {
        height: 30px;
      }

      &>div {
        display: inline-block;
        width: 60%;

        &:last-child {
          text-align: right;
          width: 40%;

          .div-upload,
          .el-button {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}


#print-view {
  position: fixed;
  left: -1000px;
}
